import { fi } from 'date-fns/locale';

const validatieAll = (
    mail: string, firstname: string, lastname: string, setError: any, prefix: string) => {

    let formIsValid = true;
    let errors: string;


    if (typeof mail !== 'undefined') {
        if (mail.includes(' ')) {
            formIsValid = false;
            errors = 'An email address can´t contain spaces.';
        } else {
            const lastAtPos = mail.lastIndexOf('@');
            const lastDotPos = mail.lastIndexOf('.');

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    mail.indexOf('@@') == -1 &&
                    lastDotPos > 2 &&
                    mail.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors = 'Please enter a valid email adress!';
            }
        }
    }
    //Empty mail
    if (mail === '') {
        formIsValid = false;
        errors = 'Please enter an email adress!'
    }

    //lastname type
    if (typeof lastname !== 'undefined') {
        if (!lastname.match(/^[a-zA-Z]+$/)) {
            formIsValid = false;
            errors = 'Please enter a valid last name!';
        }
    }

    //lastname empty
    if (lastname === '') {
        formIsValid = false;
        errors = 'Please enter a last name!';
    }

     //prefix empty
     if (prefix === '') {
        formIsValid = false;
        errors = 'Please enter a way to address you!';
    }

    //firstname type
    if (typeof firstname !== 'undefined') {
        if (!firstname.match(/^[a-zA-Z]+$/)) {
            formIsValid = false;
            errors = 'Please enter a valid first name!';
        }
    }

    //firstname empty
    if (firstname === '') {
        formIsValid = false;
        errors = 'Please enter a first name!';
    }

    setError(errors);

    if (formIsValid === true) {
        setError('')
    }
    return {
        formIsValid,
    };

}

export default validatieAll;