import React, { FC, useEffect, useState } from 'react';

import './home.scss';
import { Icon } from '../_shared';
import Slider from './slider';
import validatieAll from './validator';
import Dropdown from 'react-dropdown';

const Home: FC = () => {
  const [active, setActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [prefix, setPrefix] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [roles, setRoles] = useState<string>('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePrefixChange = (option: any) => {
    setPrefix(option.value);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const validate = () => {
    if (validatieAll(email, firstName, lastName, setError, prefix).formIsValid === true) {
      fetchData();
    }
  };

  const options = ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Prof.', 'Eng.', 'Sheikh', 'Sheikha', 'HE', 'HRH', 'HH'];

  useEffect(() => {
    if (active) {
      setRoles('Owner');
    } else {
      setRoles('Handler');
    }
  }, [active]);

  const fetchData = async () => {
    const url = 'https://development.cocom.be:3003/register';

    const data = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      howMayWeAddressYou: prefix,
      role: roles,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Request was successful!', data);
        setError('Successfully registered!');
      } else {
        if (response.status === 409) {
          setError('Email already exists!');
        } else {
          setError(`Request failed with status code: ${response.status}`);
        }
        const errorData = await response.text();
        console.log(errorData);
      }
    } catch (error) {
      setError(`An error occurred during the request: ${error}`);
    }
  };

  return (
    <div className="home">
      <div className="filterL"></div>

      <div className="left">
        <div className="header"></div>
        <div className="content">
          <h2>Unveiling Arabian Excellence</h2>
          <h3>Soon available on</h3>
          <div className="row">
            <div className="apple"></div>
            <div className="play"></div>
          </div>
          <h4>Pre-register today</h4>
          <p style={{ textAlign: 'left', marginTop: 10 }}>Choose your role:</p>
          <div className="slideRow">
            <p style={!active ? { color: '#A87D02' } : {}}>Horse Owner</p>
            <Slider active={!active} onClick={() => setActive(false)} />
          </div>
          <div className="slideRow">
            <p style={active ? { color: '#A87D02' } : {}}>Horse Handler</p>
            <Slider active={active} onClick={() => setActive(true)} />
          </div>
          <div className="inputs">
            <span>Enter your email here*</span>
            <input type="text" value={email} onChange={handleEmailChange} />

            <span>How may we address you?*</span>
            <Dropdown
              arrowClosed={<Icon name="svgDown" size={2}></Icon>}
              arrowOpen={<Icon name="svgUp" size={2}></Icon>}
              className="dropdown"
              options={options}
              onChange={handlePrefixChange}
              value={prefix}
              placeholder={'Select'}
            />
            <span>First Name*</span>
            <input type="text" value={firstName} onChange={handleFirstNameChange} />
            <span>Last Name*</span>
            <input type="text" value={lastName} onChange={handleLastNameChange} />
          </div>
          <div>
            <p
              style={
                error === 'Successfully registered!'
                  ? { marginTop: 7, minHeight: 20, color: '#0f910f', fontStyle: 'italic' }
                  : { marginTop: 7, minHeight: 20, color: '#F44040', fontStyle: 'italic' }
              }
            >
              {error}
            </p>
            <button className="registerBtn" onClick={validate}>
              <p style={{ color: 'white' }}>Pre-register</p>
            </button>
          </div>
        </div>
        <p className="bottom" style={{ fontWeight: 800 }}>
          For inquiries please contact us at <a href="mailto:info@equestrius.app">info@equestrius.app</a>
        </p>
      </div>
    </div>
  );
};
export default Home;
