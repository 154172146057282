import React from 'react';

import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { Config } from './config';
import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);
if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}


ReactDOM.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
