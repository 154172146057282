import React, { FC, Suspense, useEffect } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Menu from './_routing/menu/Menu';
import { Spinner } from './_shared';
import Footer from './footer/footer';
import Home from './home/home';

const App: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Spinner overlay size="large" />}>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};
export default App;
