import React, { FC, useEffect, useState } from 'react';

import './Slider.scss';

interface SliderProps {
    active: boolean;
    onClick: any;
}

const Slider = ({ active, onClick }: SliderProps) => {

    return (
        <button className={!active ? "slider" : "slider active"} onClick={onClick}>
            <div className='circle'>
            </div>
        </button>
    );
};
export default Slider;
